import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { title } from "../styles"
import Seo from "../components/seo"


// markup
const AboutPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-2 md:gap-8">
        <div className="max-w-screen-sm mt-12 text-center">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/cb-portrait.jpg"
            placeholder="blurred"
            loading="eager"
          />
        </div>
        <div className="max-w-screen-sm mt-12 md:pt-32">
          <h1 className={ title }>
            More about me.
          </h1>
          <div className="text-left font-sans text-sm font-normal text-battleship-grey my-2 w-4/6 md:w-full mx-auto">
            <p>I am UX/UI Designer, Product Designer, fascinated about Digital Marketing and Web Design. </p>
            <p>I love crafting delightful user experiences, making people lives better and easier, one button at a time.</p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
     
  
  )
}


export default AboutPage